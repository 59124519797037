import React from 'react'
import {formatearFecha} from '../../Helpers'
import Cargador from './Cargador'

const ListarGastos = ({gastos, loading}) => {
  const formatoMexico = (number=0) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    return number.toString().replace(exp,rep);
  }
  return (
    <>
    {loading && <Cargador/>}
    <h1 className='text-center font-bold text-2xl' >Gastos </h1>
   
    {gastos.map((dato, index)=>(      
      <div key={index} className='bg-slate-300 m-3 shadow-md px-3 py-10 w-full flex '>                                                
          <div className='p-2 md:m-3 w-full'>          
                <p className='font-bold uppercase w-full text-sm p-2 m-1'>No. {' '}<span className='font-normal'>{dato.idegreso}</span></p>              
                <p className='font-bold uppercase w-full text-sm bg-white rounded-lg p-2 m-1'>Fecha {' '}<span className='font-normal'>{formatearFecha(dato.fecha)}</span></p>            
                <p className='font-bold uppercase w-full text-sm bg-white rounded-lg p-2 m-1'>concepto {' '}<span className='font-normal me-10 p-2'>{dato.concepto}</span></p>                                                                  
                <p className='font-bold uppercase w-full text-sm bg-white rounded-lg p-2 m-1'>Monto {' '}<span className='font-normal'>${formatoMexico(dato.monto)}.00</span></p>    
                <p className='font-bold uppercase w-full text-sm bg-white rounded-md p-2 m-1'>Nombre {' '}<span className='font-normal'>{dato.nombre}</span></p>    
                <p className='font-bold uppercase w-full text-sm bg-white rounded-lg p-2 m-1'>Comentario {' '}<span className='font-normal'>{dato.notas}</span></p>    
                <p className='font-bold uppercase w-full text-sm p-2 m-1'>Recibos {' '}<span className='font-normal'>{dato.recbos}</span></p>              
          </div>                                                                                                 
      </div>
      ))}
    
    </>
  )
}

export default ListarGastos
