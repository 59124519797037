import React from 'react'
import './App.css';
import QRCode from "react-qr-code";
import {formatearFecha} from '../../Helpers'
import Cargador from './Cargador'
const ListadoRecibos = ({recibos, loading}) => {  
  const formatoMexico = (number=0) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    return number.toString().replace(exp,rep);
  }
  return (
    <>
    {loading && <Cargador/>}
    <h1 className='text-center font-bold text-2xl'>Recibos de Cuota de Mantenimiento</h1>
    {recibos.map((dato, index)=>(      
    <div key={index} className='bg-white m-3 p-5 shadow-md  w-full flex '>               
        <div  className=' w-full md:flex bg-blue-50 p-3'>
              <div className='w-1/5'>
                <QRCode
                size={256}
                style={{ height: "100", maxWidth: "100%", width: "100%" }}
                value={dato.recibo}
                viewBox={`0 0 256 256`}
              />
              </div>
              <div className='md:w-4/5 px-2 '>
                
                <p className='font-bold uppercase w-full text-sm'>Recibo {' '}<span className='font-normal me-10 p-2'>{dato.numero}</span></p>            
                <p className='font-bold uppercase w-full text-sm bg-white'><span className=''>Casa {' '} <span className='font-normal'>{dato.casa}</span></span></p>    
                <p className='font-bold uppercase w-full text-sm'>Fecha {' '}<span className='font-normal'>{formatearFecha(dato.fecha)}</span></p>                           
                <p className='font-bold uppercase w-full text-sm bg-white'>Concepto {' '}<span className='font-normal'>{dato.concepto}</span></p>    
                <p className='font-bold uppercase w-full text-sm'>Monto {' '}<span className='font-normal'>${formatoMexico(dato.monto)}.00</span></p>    
                <p className='font-bold uppercase w-full text-sm bg-white'>Medio {' '}<span className='font-normal'>{dato.medio}</span></p>    
                <p className='font-bold uppercase w-full text-sm '>Observaciones {' '}<span className='font-normal'>{dato.observaciones}</span></p>    
                <p className='font-bold uppercase w-full text-sm bg-white'>Folio {' '}<span className='font-normal'>{dato.recibo}</span></p>              
              </div>                                                                                       
        </div>
    </div>
    ))}
    </>
  )
}

export default ListadoRecibos
