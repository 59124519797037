import {  useState } from 'react';
import MensajeError from './MensajeError'
import clienteAxios from '../../config/axiox'
import MensajeOK from './MensajeOk'
 
 const Registro = () => {
    const [celular, setCelular]=useState("");
    const [userEmail, setEmail]=useState("");
    const [clave, setClave]=useState("");     
    const [casa, setCasa]=useState("");    
    const [nombre, setNombre]=useState('')
    const [validaCalve, SetValidaCalve]=useState('')
    const [hayError, setHayError]=useState(false)
    const [msgError, setMesgError]=useState("")
    const [msgOK, setMesgOk]=useState("")

     

     
    const validarCalves= async()=>{
        if(celular.length!==10){
            setMesgError("El número de celular con 10 dígitos")  
             setHayError(true)        
         }else if(nombre.length<5){
            setMesgError("El nombre no puede estar vacío. Ingresa tu nombre completo!")  
            setHayError(true)      
         }else if(userEmail.length<5){
            setMesgError("El correo no puede estar vacío. Ingresa tu correo completo!")  
            setHayError(true)      
         }else if(casa<1 || casa >34){
            setHayError(true)        
            setMesgError("Error en número de casa. Ingresa del 1 al 34")
        }else if(clave!==validaCalve){
            setHayError(true)
            setMesgError("Claves no coinciden!")
        }else if(clave===''){
          setHayError(true)
          setMesgError("Captura una clave de acceso!")
      }else{
            setHayError(false)
            setMesgError("")
 
        const data ={   
            "op":"registro",         
            "celular":celular,
            "nombre":nombre,
            "correo":userEmail,
            "clave":clave,
            "casa":casa,
            "activo":1
          }
          const config = {
            headers: {
                "Content-Type": "application/json",      
            }        
          }            
          try{   
           
            const datas =await clienteAxios.post('api/usuarios/', data, config)
          
            let {error, message}=datas.data  
            console.info("datas:"+error) 
            if(error){
                setHayError(true)     
                setMesgError(message)
            }else{
                
              setMesgOk("Bienvenid@, ahora ya puedes iniciar sesión")
              cleanLocalStorage()
            }            
          }catch(err){                            
            setMesgError("Datos de acceso incorrectos!!!")
          }
        }


    }

    const cleanLocalStorage=()=>{      
      localStorage.clear()
      setEmail("");       
      setClave("");        
      setCelular("");
      setNombre("")
      setCasa(0)
      setClave("")
      SetValidaCalve("")
      
  }
   return (
     <>
         <div className='lg:w-1/2 m-3 bg-blue-100 md:p-10 sm:p-5 mt-5 mx-auto'>               
               <h1 className=' text-center text-3xl'>Formulario de Registro</h1>


               <div>
                {hayError && 
                    <MensajeError msgError={msgError} />     
                }
                {MensajeOK && 
                    <MensajeOK msgOk={msgOK} />     
                }
                  
               </div>

               <label className='block  mt-2 uppercase font-bold text-sm ' htmlFor='celular'>No.Celular</label>
               <input type="number" id='celular'                           
               className="border-2 w-full p-2 rounded-sm placeholder-gray-400 " 
               placeholder='Ingresa tu número de celular' min={10} max={10}
               value={celular} onChange={({target: {value}})=>Number(setCelular(value))}
               />      
 
               <label className='block  mt-3 uppercase font-bold text-sm' htmlFor='nombre'>Nombre</label>
               <input type="text" id='nombre'
               className="border-2 w-full p-2 rounded-sm placeholder-gray-400 " 
               placeholder='Ingresa tu nombre completo'
               value={nombre} onChange={({target: {value}})=>setNombre(value)}
               />      
 
               <label className='block  mt-3 uppercase font-bold text-sm' htmlFor='correo'>Correo</label>
               <input type="email" id='correo'
               className="border-2 w-full p-2 rounded-sm placeholder-gray-400 " 
               placeholder='Ingresa tu correo electrónico'
               value={userEmail} onChange={({target: {value}})=>setEmail(value)}
               />      
 
               <label className='block  mt-3 uppercase font-bold text-sm' htmlFor='casa'>Casa</label>
               <input type="number" id="casa"
               className="border-2 w-full p-2 rounded-sm placeholder-gray-400 " 
               placeholder='Ingresa tu número de casa'
               value={casa} onChange={({target: {value}})=>setCasa(value)}
               />
 
               <label className='block  mt-3 uppercase font-bold text-sm' htmlFor='clave'>Clave</label>
               <input type="password" id='clave'
               className="border-2 w-full p-2 rounded-sm placeholder-gray-400 " 
               placeholder='Ingresa una contraseña'
               value={clave} onChange={({target: {value}})=>setClave(value)}
               />      
 
               <label className='block  mt-3 uppercase font-bold text-sm' htmlFor='confirmacion'>Confirma tu clave</label>
               <input type="password"  id='confirmacion'
               className="border-2 w-full p-2 rounded-sm placeholder-gray-400 " 
               placeholder='Ingresa una contraseña'
               value={validaCalve} onChange={({target: {value}})=>SetValidaCalve(value)}
               />      
               
               <a href='/' className='m-3 bg-gray-700 hover:bg-gray-800  py-2 px-4 rounded float-right text-white'>Cancelar</a>
               <button className='m-3 bg-blue-700 hover:bg-blue-800  py-2 px-4 rounded float-right text-white' onClick={validarCalves}>Registrarme</button>
              
               
           </div>
     </>
   )
 }
 
 export default Registro
 