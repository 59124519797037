import React from 'react'
import Select from 'react-select'

const Casas = ({userEmail, casa, setCasa, idperfil}) => {
  let numeroCasa=[]  
  if(idperfil=='99'){
    numeroCasa= [
      {value:1, label:'Casa 1'},
      {value:2, label:'Casa 2'},
      {value:3, label:'Casa 3'},
      {value:4, label:'Casa 4'},
      {value:5, label:'Casa 5'},
      {value:6, label:'Casa 6'},
      {value:7, label:'Casa 7'},
      {value:8, label:'Casa 8'},
      {value:9, label:'Casa 9'},
      {value:10, label:'Casa 10'},
      {value:11, label:'Casa 11'},
      {value:12, label:'Casa 12'},
      {value:13, label:'Casa 13'},
      {value:14, label:'Casa 14'},
      {value:15, label:'Casa 15'},
      {value:16, label:'Casa 16'},
      {value:17, label:'Casa 17'},
      {value:18, label:'Casa 18'},
      {value:19, label:'Casa 19'},
      {value:20, label:'Casa 20'},
      {value:21, label:'Casa 21'},
      {value:22, label:'Casa 22'},
      {value:23, label:'Casa 23'},
      {value:24, label:'Casa 24'},
      {value:25, label:'Casa 25'},
      {value:26, label:'Casa 26'},
      {value:27, label:'Casa 27'},
      {value:28, label:'Casa 28'},
      {value:29, label:'Casa 29'},
      {value:30, label:'Casa 30'},
      {value:31, label:'Casa 31'},
      {value:32, label:'Casa 32'},
      {value:33, label:'Casa 33'},
      {value:34, label:'Casa 34'}

    ]    
  } 

  const handleChange = event => {        
    setCasa(event.value);
  };

     
  return (
    <>
    {idperfil=='99' && 
      <Select options={numeroCasa} key={numeroCasa.value}  onChange={handleChange}/>
    }
    </>
  )
}

export default Casas
