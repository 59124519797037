
 const MensajeOK = ({msgOk}) => {
   return (
     
        <div className={msgOk?"bg-lime-100 border border-lime-400 text-lime-700 p-1 mt-2 rounded relative":""} role="alert">
                {msgOk}
        </div> 
     
   )
 }
 
 export default MensajeOK
 