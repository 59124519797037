import Select from 'react-select';
const Lista = ({label, defaultValue, options, selected_option, setOnChange})=>{

    return(
        <>
        <label className='label-control'>{label}</label>
        <Select classNames={'form-control'}
                  isSearchable="true"
                  defaultValue={defaultValue}
                  className="basic-multi-select" 
                  classNamePrefix="select" 
                  options={options} 
                  value={selected_option}
                  onChange={(item)=>setOnChange(item)} 
                  required               
                />
        </>
    )
}
export default Lista;