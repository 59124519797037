import { useEffect, useState } from 'react';
import './App.css';
import FormularioLogin from './FormularioLogin'
import Home from './Home';
 

function Index() {
    const [celular, setCelular]=useState("");
    const [userEmail, setEmail]=useState("");
    const [clave, setClave]=useState("");     
    const [casa, setCasa]=useState("");
    const [userName, setUserName]=useState("");
    const [token, setToken]=useState("");
    const [isLogin, setLogin]=useState(false);
    const [msgError, setMesgError]=useState("");
    const [recibos, setRecibos]=useState([]);
    const [fechaInicial, SetFechaInicial]=useState("");
    const [fechaFinal, SetFechaFinal]=useState("");
    const [nombre, setNombre]=useState('');
    const [perfil, setPerfil]=useState('');
    const [idperfil,setIDPerfil]=useState(0);
    const [loading,setLoading]=useState(false);

    const getLocalStorage=()=>{        
        let token2=localStorage.getItem('_token');
        setToken(localStorage.getItem('_token'));
        setUserName(localStorage.getItem('_userName'))    
        setEmail(localStorage.getItem('_userEmail'))
        setCelular(localStorage.getItem('_celular'))
        setCasa(localStorage.getItem('_casa'))  
        setPerfil(localStorage.getItem('_perfil'))  
        setIDPerfil(localStorage.getItem('_idperfil'))  
        token2===null?setLogin(false):setLogin(true)        
    }
      
    useEffect(()=>{    
        getLocalStorage()     
    },[]) 
    useEffect(()=>{    
        
    },[nombre, casa]) 

 


return(
    <>
                <div className="container lg:w-100 mt-5 p-3 mx-auto">
                    <div className='container bg-white shadow-xl rounded-lg py-10 px-5 mb-10'>   
                                                   

                        {isLogin ? 
                        <Home                                                 
                            SetFechaInicial={SetFechaInicial} 
                            SetFechaFinal={SetFechaFinal}                                                                                                
                            recibos={recibos}                 
                            fechaFinal={fechaFinal}  
                            fechaInicial={fechaInicial}                                                                       
                            token={token}                           
                            setRecibos={setRecibos}                              
                            setToken={setToken}                          
                            setLogin={setLogin}
                            setCelular ={setCelular }
                            setClave={setClave} 
                            setUserName={setUserName}  
                            setEmail={setEmail}
                            casa={casa}    
                            setCasa={setCasa} 
                            userName={userName}
                            userEmail={userEmail}  
                            perfil={perfil}
                            idperfil={idperfil}    
                            setPerfil={setPerfil}                               
                            setIDPerfil={setIDPerfil}  
                            loading={loading}                     
                            setLoading={setLoading}
                        />                    
                        :   (  
                          
                            <FormularioLogin                                               
                                msgError={msgError}                                                      
                                setMesgError={setMesgError}
                                setToken={setToken}       
                                setLogin={setLogin}    
                                celular={celular}
                                setCelular ={setCelular }
                                userEmail={userEmail} 
                                setEmail={setEmail}
                                clave={clave}
                                setClave={setClave} 
                                casa={casa}
                                setCasa={setCasa}  
                                userName={userName}
                                setUserName={setUserName}  
                                nombre={nombre}
                                setNombre={setNombre}                                 
                                setPerfil={setPerfil}                               
                                setIDPerfil={setIDPerfil}
                            />                                                                          
                      )
                    }     
                    </div>       
            </div>               
    </>
    );
}
 
export default Index;