
import Lista from "../../Helpers/Select"
const Direccion=({setCelular, celular})=>{

return(
<>
<h1 className='title-control mt-5'>Dirección del Cliente </h1> 
          <div className='div-control p-3 w-full grid grid-cols-6'>                          
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-1'>
                    <Lista label={'País'}/>
                </div>
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-1'>
                    <Lista label={'Estado'}/>
                </div>
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-2'>
                    <Lista label={'Municipio'}/>
                </div>
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-2'>
                    <Lista label={'Localidad'}/>
                </div>
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-1'>
                    <label className='label-control'>CP</label>             
                    <input type='text' className="form-control " required value={celular} onChange={({target: {value}})=>setCelular(value)}/>
                </div>
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-2'>
                    <label className='label-control'>Número Exterior</label>             
                    <input type='text' className="form-control " required value={celular} onChange={({target: {value}})=>setCelular(value)}/>
                </div>
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-1'>
                    <label className='label-control'>Número Interior</label>             
                    <input type='text' className="form-control " required value={celular} onChange={({target: {value}})=>setCelular(value)}/>
                </div>                
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-2'>
                    <Lista label={'Tipo de Casa'}/>
                </div>                
          </div> 
</>

)
}

export default Direccion