import React from 'react'

const Reglamento = () => {
  return (
    <div className='p-20'>
            <p className='p-10 text-2xl text-bold text-center'><span>PROPUESTA DE REGLAMENTO</span></p>
            <h1 className='text-lg text-bold font-bold p-5' >CAPITULO I   CUOTA DE MANTENIMIENTO </h1>
            <p className='ps-20 p-2'>1.- EL PAGO DE MANTENIMIENTO Y SERVICIOS ES OBLIGATORIO Y  TENDRÁ QUE SER PAGADO A LA MESA DIRECTIVA DENTRO  CINCO PRIMEROS DÍ DE CADA MES,  CONCEPTO PAGO CUBRE LOS  CONCEPTOS:</p>            
            <p className='ps-20 p-2'>2.- EL CONDÓMINO QUE NO APORTE SU COATA EN LOS CINCO PRIMEROS DÍAS, SERÁ ACREEDOR AL PAGO DE 100. (CIEN PESOS 00/ M. N.).  MOROSIDAD</p>
            <p className='ps-20 p-2'><span>3.- LA MESA DIRECTIVA EN FUNCIONES, TENDRÁ LA OBLIGACIÓN DE   FINANCIERAS DE MANERA MENSUAL</span></p>
            <h1 className='text-lg text-bold font-bold p-5'>CAPITULO ll  ÁREAS VERDES </h1>
            <p className='ps-20 p-2'>1.- EL ÁREA   SE ENCUENTRA LA FUENTE, SERÁ EXCLUSIVO PARA LA BUENA CONVIVENCIA ENTRE PERSONAS, POR LO QUE NO ESTA PERMITIDO INGRESAR MASCOTAS.</p>
            <p className='ps-20 p-2'>2.- EN CASO DE QUE SE EFECTUÉ ALGÚN TIPO DE REUNIÓN, LOS ORGANIZADORES SON RESPONSABLES DE DEJAR EL ÁREA LIMPIA, EN CASO  DAÑOS SERÁN CUBIERTOS POR LOS MISMO.</p>
            <p className='ps-20 p-2'><span>3.- QUEDA PROHIBIDO EL CONSUMO DE BEBIDAS ALCOHÓLICAS.</span></p>
            <h1 className='text-lg text-bold font-bold p-5'>CAPITULO III  CONVIVENCIAS WO FIESTAS FAMILIARES </h1>
            <p className='ps-20 p-2'>1- SI  EFECTUAR UNA CONVIVENCIA  EL ÁREA DE LA FUENTE, DEBERÁN SOLICITARLO  PARA SU VOS BO. A LA MESA DIRECTIVA EN FUNCIONES.</p>
            <p className='ps-20 p-2'>2.- RESPETAR EL DESCANSO FAMILIAR,  HORARIOS DE LAS FIESTAS SERÁN LOS SIGUIENTES:</p>            
            <p className='ml-20 pl-20'> -MANTENIMIENTO</p>
            <p className='ml-20 pl-20'> -SERVICIOS</p>
            <p className='ml-20 pl-20'> -HIDRONEUMÁTICO (DIARIO)</p>
            <p className='ml-20 pl-20'> -PAGO DE LUZ DEL HIDRONEUMÁTICO</p>
            <p className='ml-20 pl-20'> -BOMBA DE AGUA</p>
            <p className='ml-20 pl-20'> -PAGO  LUZ  ALUMBRADO</p>
            <p className='ml-20 pl-20'> -REAS VERDES EXTERIOR</p>
            <p className='ml-20 pl-20'> - EN CADA CONDÓMINO</p>
            <p className='ml-20 pl-20'> -PAGO DE ENERG A  CTRICA DE LA  AUTOMATIZADA</p>
            <p className='ml-20 pl-20'> -FUENTE</p>
            <p className='ml-20 pl-20'> -PAGO DE TELÉFONO DE CASETA</p>
            <p className='ml-20 pl-20'> -ÁREAS COMUNES</p>
            <p className='ml-20 pl-20'> -ADQUISICIÓN DE INSUMOS DE pMPIEZA</p>
            <p className='ml-20 pl-20'> -pMPIEZA DE  EXTERNAS DE CADA CONDÓMINO</p>
            <p className='ml-20 pl-20'> -PAGO DE SEGURIDAD SOCIAL DEL PERSONAL  MANTENIMIENTO</p>
            <p className='ml-20 pl-20'> -TIRADO BASURA</p>
            <p className='ml-20 pl-20'> -APpCACI N DE  EXTERIOR  LA PRIVADA</p>
            

            
            
            
            <p className='ps-20 p-2'>3.- LOS ORGANIZADORES DE LAS FIESTAS, SERÁN  DEL COMPORTAMIENTO DE LOS ACTOS DE SUS INVITADOS, POR LO SI SE COMETIERA UN DAÑO A TERCERO,  EL DAÑO A VEHÍCULOS Y/O  PRIVADA, DEBERÁN PAGAR A  AFECTADOS LOS DAÑOS CAUSADOS.</p>
            <p className='ps-20 p-2'><span>4.- QUEDA PROHIBIDO LA ENTRADA A LOS AUTOMÓ DE LOS INVITADOS, YA  PARA ESO  CUENTA CON UN ÁREA DE ESTACIONAMIENTO.</span></p>
            <h1 className='text-lg text-bold font-bold p-5'>CAPITULO   MASCOTAS </h1>
            <p className='ps-20 p-2'><span>1.-  PROHIBIDO EL  TRANSITO DE LAS MASCOTAS, ESTAS SIEMPRE DEBERÁN SER ACOMPAÑADAS POR EL DUEÑO.</span></p>
            <p className='ps-20 p-2'>2.- NO OBSTANTE A QUE SE CUENTA CON UN SERVICIO DE  EN  ÁREAS COMUNES, LOS DUEÑ DE LAS MASCOTAS SON RESPONSABLES DE LA RECOLECCIÓN  LOS DESECHOS BIOLÓ  ESTOS.</p>
            <p className='ps-20 p-2'>3.- EL ÁREA DE LA FUENTE ES EXCLUSIVO PARA  CONVIVENCIA DE NIÑOS Y ADULTOS, POR LO QUE QUEDA PROHIBIDO QUE SEAN UTILIZADAS PARA LA RECREACIÓN Y NECESIDADES BIOLÓGICAS DE LAS MASCOTAS.</p>
            <h1 className='text-lg text-bold font-bold p-5'>CAPITULO V  ACCESO A LA PRIVADA </h1>
            <p className='ps-20 p-2'>1.- CON LA FINALIDAD DE RESGUARDAR LA SEGURIDAD DE LOS HABITANTES Y CASAS HABITACIÓN NO SE PERMITIRÁ EL ACCESO A VENDEDORES AMBULANTES Y CAMIONETAS DE SERVICIO (A EXCEPCIÓN DEL DISTRIBUIDOR DEL S (SIEMPRE Y CUANDO ESTE SEA DE BAJA CAPACIDAD, CON LA FINALIDAD DE EVITAR EL DAÑO DEL ADOQUÍN).</p>
            <p className='ps-20 p-2'><span>2.- LA  MÁXIMA  A CIRCULAR POR  VEHÍCULOS SERÁ DE 15 KM/HR.</span></p>
            <p className='ps-20 p-2'>3.- A LA ENTRADA Y SALIDA DE  VEHÍCULOS, EL CONDUCTOR DEBERÁ CERCIORARSE EN DEJAR LA PUERTA ELÉCTRICA CERRADA.</p>
            <p className='ps-20 p-2'><span>4.- QUEDA PROHIBIDO EL INGRESO A TAXIS,  LA SEGURIDAD DE LOS HABITANTES.</span></p>
            <p className='ps-20 p-2'><span>5.- QUEDA PROHIBIDO SER USO DE ESTACIONAMIENTO EN LAS CASAS HABITACIÓN AJENAS.</span></p>
            <h1 className='text-lg text-bold font-bold p-5'>CAPITULO V  MESA DIRECTIVA </h1>
            <p className='ps-20 p-2'>1.- LA MESA DIRECTIVA PERMANECERÁ EN FUNCIONES POR PERIODOS   MESES. POR LO ANTERIOR, SE TENDRÁ QUE ELEGIR UNA NUEVA MESA DIRECTIVA UN MES ANTES DE CONCLUIR LAS  DE   EN FUNCIONES.</p>
            <p className='ps-20 p-2'>2.- LAS MESAS DIRECTIVAS SE CONFORMARAN DE MANERA VOLUNTARIA O POR PROPUESTA DE LA MESA SALIENTE.</p>
            <p className='ps-20 p-2'>3.- LA MESA DIRECTIVA PUEDE   POR PROPIETARIOS O ARRENDADORES DE LAS VIVIENDAS.</p>
            <p className='ps-20 p-2'>4.- SERÁ OBLIGATORIO DE LOS PROPIETARIOS Y ARRENDADORES PERMANECER EN LA MESA DIRECTIVA  VEZ  TRES AÑOS.</p>

            <p className='ps-20 p-2'><span>19 DE febrero de 2006</span></p>
      
    </div>
  )
}

export default Reglamento
