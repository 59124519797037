import { useState } from 'react';
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions
} from 'react-swipeable-list'
import "react-swipeable-list/dist/styles.css"

const ListarDetalle = ({casa, deudores }) => {

  const [Id, setId]=useState(0)
  const [Mes, setMes]=useState("")


 
     
    const formatoMexico = (number=0) => {
        const exp = /(\d)(?=(\d{3})+(?!\d))/g;
        const rep = '$1,';
        return number.toString().replace(exp,rep);
      }

      const leadingActions=(id)=>(
        <LeadingActions>
          <SwipeAction onClick={()=>{console.log("editar"+id)}}>
            Editar
          </SwipeAction>
        </LeadingActions>      
      )
      const trailingActions=(id)=>(
        <TrailingActions>
           <SwipeAction onClick={()=>{console.log("editar"+id)}}>
            Editar
          </SwipeAction>
        </TrailingActions>
      )
      

  return (
    <>
    <h1 className='text-center font-bold text-2xl pt-3 ' >Adeudos por mes</h1>    
    <h1 className='text-start font-bold text-lg ms-10 mt-5' >Detalle: </h1>

    
    
    
    {deudores && deudores.map((dato, index)=>(                                                            
       <SwipeableList>
        <SwipeableListItem
          leadingActions={leadingActions(dato.id)}
          trailingActions={trailingActions(dato.id)}
        >
          <div className='sombra'>
                <p className='font-bold uppercase w-full whitespace-nowrap text-md bg-white rounded-lg '><span className='font-normal'>{dato.anio}</span></p>            
                <p className='font-bold uppercase w-full whitespace-nowrap text-md bg-white rounded-lg '><span className='font-normal'>{dato.mes}</span></p>                            
                <p className='font-bold uppercase w-full whitespace-nowrap text-md bg-white rounded-lg '><span className='font-normal'>${formatoMexico(dato.cuota)}.00</span></p>                            
                <p className='font-bold uppercase w-full whitespace-nowrap text-md bg-white rounded-lg '><span className='font-normal'>${formatoMexico(dato.pago)}.00</span></p>                            
                                  
          </div> 
          </SwipeableListItem>
          </SwipeableList>                
      ))}
      



    
    </>
  )
}

export default ListarDetalle
