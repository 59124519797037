import './App.css';
import MensajeError from './MensajeError';
import clienteAxios from '../../config/axiox';
import fraccionamiento from '../../img/fraccionamiento.jpg'
const FormularioLogin = (
        {msgError,setMesgError, setToken, setLogin,celular, setCelular ,userEmail, setEmail, clave, setClave ,setCasa , setUserName, setPerfil, setIDPerfil}
    ) => { 
    
                    
    const AsignarValores=(valor)=>{    
        setEmail(valor)
        setCelular(valor)
    }
          
    const IniciarSesion= async(event)=>{
        event.preventDefault();          
        if( [celular, clave, userEmail].includes('')){
            setMesgError("Debe ingresar todos los datos")
        }else{
        const data ={   
            "op":"login",         
            "celular":celular,
            "correo":userEmail,
            "clave":clave
          }
          const config = {
            headers: {
                "Content-Type": "application/json",      
            }        
          }            
          try{   
            const datas =await clienteAxios.post('api/usuarios/login', data, config)            
            let {acceso, token, correo, nombre, celular, casa, activo, idperfil, perfil}=datas.data   
            if(activo===0){
              setLogin(false);
              setMesgError(datas.data.mensaje)
            }else if(acceso===1 && token){     
                setEmail(correo);
                setUserName(nombre);
                setCelular(celular)
                setToken(token);
                setCasa(casa);
                setPerfil(perfil);
                setIDPerfil(idperfil)
                setMesgError('')
    
                localStorage.setItem('_token',token)
                localStorage.setItem('_userName',nombre)
                localStorage.setItem('_userEmail',correo)
                localStorage.setItem('_celular',celular)
                localStorage.setItem('_casa',casa)
                localStorage.setItem('_perfil',perfil)
                localStorage.setItem('_idperfil',idperfil)
            
                setLogin(true);
            }else{
                setLogin(false);
                setMesgError(datas.data.mensaje)
            }
          }catch(err){          
            setLogin(false);         
            setMesgError("Datos de acceso incorrectos!!!")
          }
         
        }
    }
  
  return (
    <>         
                    
          <div className='lg:flex'>
         
          <div  className='lg:w-1/2 m-3 md:p-10 sm:p-5 mt-5 bg-gray-100 mx-auto'>   

          
            <form onSubmit={IniciarSesion}>                   
                  <div className="bg-azul-obscuro text-white">
                    <h1 className="text-5xl text-center p-5">Villas Fontana II</h1>
                  </div>   
                    <div className="mt-10">                            
                            <div className=""> 
                                <label className='block text-gray-700 uppercase font-bold'>No. Celular</label>
                                <input type="text"                           
                                className="border-2 w-full p-2 m-2 rounded-md placeholder-gray-400 " 
                                placeholder='Ingresa tu número de celular'
                                value={userEmail} onChange={({target: {value}})=>AsignarValores(value)}
                                />                                
                            
                            </div>
                    </div>
                            
                    <div className=""> 
                        <div className="mt-5">             
                            <label className='block text-gray-700 uppercase font-bold'>Contraseña</label>
                            <input 
                            type="password"                    
                            placeholder='Ingresa tu clave de acceso'
                            className="border-2 w-full p-2 m-2 rounded-md placeholder-gray-400 " 
                            value={clave} onChange={({target: {value}})=>setClave(value)}
                            />                      
                        </div>               
                    </div>                           
                    <div className='flex'>
                        <button className="bg-azul w-2/3 p-3  text-white uppercase hover:bg-blue-500 cursor-pointer transition-colors mx-auto " type="submit">Ingresar</button>
                        
                    </div>                                                            
                    <MensajeError msgError={msgError} />                                                                      
            </form> 
          </div>
        
         
          </div>
      
    </>
  )
}

export default FormularioLogin;
