import React from 'react'
import clienteAxios from '../../config/axiox';
import { useState, useEffect } from 'react';
import Casas from './Casas'
import MensajeOK from './MensajeOk'

const RegistroGasto = ({
  token
}) => {
  const [fecha, setFecha]=useState("");
  const [concepto, setConcepto]=useState("")
  const [monto, setMonto]=useState(0)
  const [nombre, setNombre]=useState("")
  const [recibo, setRecibo]=useState("")
  const [notas, setNotas]=useState("")

  const msgOk="Se registró correctamente el recibo";

      const limpiar=()=>{
        setFecha("")
        setConcepto("")
        setMonto(0)
        setNombre("")
        setRecibo("")
        setNotas("")
      }
  
      useEffect(()=>{    
        limpiar()
    },[]) 
    
    const RegistrarGasto =async(event)=>{
        event.preventDefault(); 
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }
  
       
      
      if(token){
      // recibo, fecha, mes, casa, concepto, monto, medio, observaciones,
        const datos = {
            "op":"registro",                
            "fecha":fecha,
            "concepto":concepto,
            "monto":monto,
            "nombre":nombre,
            "notas":notas
        }     
       
                 
           await clienteAxios.post(`api/egresos/`, datos, config)
            .then(res=>{                 
              setRecibo(res.data.data[0].rescibos)
              const interval = setInterval(() => {
                limpiar()             
              }, 3000);
            })
          }

    }
   
  return (
    <>
   <form onSubmit={RegistrarGasto}>  
      <div className='div-control p-3'>
          {!recibo=='' && <MensajeOK msgOk={msgOk}/>}
          <h1 className='title-control'>Registro de Gasto <span>{recibo}</span></h1> 
          <label className='label-control'>Fecha </label>
          <input type='date' className="form-control " value={fecha} onChange={({target: {value}})=>setFecha(value)}/>
          <label className='label-control'>Monto</label>
          <input   className="form-control " type='number' value={monto} onChange={({target: {value}})=>setMonto(value)}/>                                       
          <label className='label-control'>Concepto</label>
          <textarea id="message" rows="4" className="form-control" placeholder="" value={concepto}  onChange={({target: {value}})=>setConcepto(value)}></textarea>                 
          <label className='label-control'>Nombre</label>
          <input   className="form-control " type='text' value={nombre} onChange={({target: {value}})=>setNombre(value)}/>
          <label className='label-control'>Nota</label>
          <textarea id="message" rows="4" className="form-control" placeholder="" value={notas} onChange={({target: {value}})=>setNotas(value)}></textarea>
          <button className="boton-control" type="submit">Registrar</button>          
      </div>
      </form>
    </>
  )
}

export default RegistroGasto
