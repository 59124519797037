import React from 'react'
import Casas from './Casas'
import clienteAxios from '../../config/axiox';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import MensajeOK from './MensajeOk'
const RegistroPago = ({  
  userEmail,
  casa,
  setCasa, 
  idperfil,
  token
}) => {

  const [fecha, setFecha]=useState("");
  const [concepto, setConcepto]=useState("")
  const [monto, setMonto]=useState(0)
  const [medio, setMedio]=useState("Transferencia")
  const [recibo, setRecibo]=useState("")
  const [observaciones, setObservaciones]=useState("")
  const [recivos, setRecibos]=useState([])
  const [adeudosPorPagar, setAdeudosPorPagar]=useState([])
  const [periodos, setPeriodos]=useState()
  
  

  const msgOk="Se registró correctamente el recibo";
  let medios= [
    {value:'Transferencia', label:'Transferencia'},
    {value:'Efectivo', label:'Efectivo'},

  ]

   
  const consultarAdeudos=async()=>{
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }

    if(token){
        const datos = {
            "casa":casa
        }              
        let valoresPorPagar=[]           
           await clienteAxios.post(`api/recibos/adeudos`, datos, config)
            .then(res=>{                                                                                                                             
                res.data.data.porpagar.forEach(element => {                                      
                    const {id, periodo}=element
                    let dato={value:id, label: periodo}            
                    valoresPorPagar=[...valoresPorPagar, dato]                 
                 });                                  
                setAdeudosPorPagar(valoresPorPagar)                
            }).catch(err=>{
                console.log(err)
                alert(err.message)
            });                                             
           
               
                       
    }
  }

  const ConceptoDePago=(item)=>{
      setPeriodos(item)       
      let periodos='Mantenimiento: ';
      item.forEach(x=>{        
        periodos+=x.label+'.'
      })      
      setConcepto(periodos)
  }
 
useEffect(()=>{    
  consultarAdeudos()     
},[]) 

  const limpiar=()=>{
    setFecha("")
    setConcepto("")
    setMonto(0)
    setMedio("Transferencia")
    setRecibo("")
    setObservaciones("")
  }
  
  
  
  const RegistrarPago=async(event)=>{
    event.preventDefault(); 
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }       
  if(token){
    // recibo, fecha, mes, casa, concepto, monto, medio, observaciones,
  

      const datos = {
          "op":"registro",    
          "casa":casa,
          "fecha":fecha,
          "concepto":concepto,
          "monto":monto,
          "medio":medio,
          "observaciones":observaciones,
          "periodos":periodos,
          
      }        
      let valores=[]           
         await clienteAxios.post(`api/recibos/`, datos, config)
          .then(res=>{                
            setRecibo(res.data.data[0].recibo)
            const interval = setInterval(() => {
              limpiar()             
            }, 3000);
          })
        }
  }
  return (
    <>

    <form onSubmit={RegistrarPago}>  
      <div className='div-control p-3'>
          {!recibo=='' && <MensajeOK msgOk={msgOk}/>}
          <h1 className='title-control'>Registro de Pago <span>{recibo}</span></h1> 
          <label className='label-control'>Fecha</label>
          <input type='date' className="form-control " value={fecha} onChange={({target: {value}})=>setFecha(value)} required/>          
          <label className='label-control'>Casa</label>
          <Casas userEmail={userEmail} casa={casa} setCasa={setCasa} idperfil={idperfil} />  
          <label className='label-control'>Periodo(s)</label>
          <Select defaultValue={[adeudosPorPagar[0]]} isMulti name="porpagar" options={adeudosPorPagar} className="basic-multi-select"  classNamePrefix="select" onChange={(item)=>ConceptoDePago(item)} required/>          
          <label className='label-control'>Concepto</label>
          <textarea id="message" rows="4" className="form-control" placeholder="" value={concepto} onChange={({target: {value}})=>setConcepto(value)} readOnly></textarea>          
          <label className='label-control'>Monto</label>
          <input   className="form-control " type='number' value={monto} onChange={({target: {value}})=>setMonto(value)} required/>          
          <label className='label-control'>Medio</label>
          <input   className="form-control " type='text' required value={medio} onChange={({target: {value}})=>setMedio(value)}/>
          <label className='label-control'>Observaciones</label>
          <textarea id="message" rows="4" className="form-control" placeholder="" value={observaciones} onChange={({target: {value}})=>setObservaciones(value)}></textarea>
          <button className="boton-control " type="submit">Registrar</button>         
      </div>
      </form>
    </>
  )
}

export default RegistroPago 