import React from 'react'
import clabe from '../../img/clabe.jpeg'
import logo_spei from '../../img/spei.png'
import logo_aurrera from '../../img/logo_aurrera.jpg'
import aurrera from '../../img/aurrera.jpeg'
import logo_sams from '../../img/logo_sams.jpg'
import sams from '../../img/sams.jpeg'
import logo_waltmart from '../../img/logo_waltmar.jpg'
import waltmart from '../../img/walmart.jpeg'
import logo_seven from '../../img/logo-seven.jpg'
import seven from '../../img/eleven.jpeg'

const Establecimientos = () => {
  return (
    <div className='p-5'>
      {/* <h1 className='font-bold text-4xl text-center md:p-20'>¿Dónde y cómo puedo pagar el servicio de mantenimiento?</h1> */}
      {/* <p className='text-2xl m-10 text-center'>Vecin@ existen diferentes formas de realizar tu pago de mantenimiento, te recomendamos hacerlo de las siguientes formas para que sea más eficiente y seguro para todos.</p> */}

      <div className='md:flex'>
        <div className='md:w-1/2 mx-auto'>
            {/* <img src={logo_spei}/> */}
            {/* <p className='m-3 text-lg'><span className='font-bold'>1.-</span> Transferencia electrónica clabe: <span className='font-bold'>646180146003279144</span></p> */}
        </div>
        <div className='md:w-1/2 bg-orange-400 mx-auto'>
            
            {/* <img src={clabe} title='Clabe'  className='rounded-lg m-3'/> */}
        </div>
      </div>

      <div className='md:flex '>
        <div className='md:w-1/2'>
        
            {/* <img src={logo_aurrera}/> */}
            {/* <p className='m-3 text-lg'><span className='font-bold'>2.-</span> Pago en efectivo </p> */}
        </div>
        <div className='md:w-1/2 bg-green-600'>            
            {/* <img src={aurrera} title='Clabe'  className='rounded-lg m-3'/> */}
        </div>
      </div>


      <div className='md:flex'>
        <div className='md:w-1/2'>
            {/* <img src={logo_sams}/> */}
            {/* <p className='m-3 text-lg'><span className='font-bold'>3.-</span> Pago en efectivo </p> */}
        </div>
        <div className='md:w-1/2 bg-sky-700'>            
            {/* <img src={sams} title='Clabe'  className='rounded-lg m-3'/>             */}
        </div>
      </div>

      <div className='md:flex '>
        <div className='md:w-1/2'>
            {/* <img src={logo_seven}/> */}
            {/* <p className='m-3 text-lg'><span className='font-bold'>4.-</span> Pago en efectivo </p> */}
        </div>
        <div className='md:w-1/2 bg-emerald-700'>            
            {/* <img src={seven} title='Clabe'  className='rounded-lg m-3'/> */}
        </div>
      </div>

      <div className='md:flex '>
        <div className='md:w-1/2'>
            {/* <img src={logo_waltmart}/> */}
            {/* <p className='m-3 text-lg'><span className='font-bold '>5.-</span> Pago en efectivo </p> */}
        </div>
        <div className='md:w-1/2 bg-sky-400'>            
            {/* <img src={waltmart} title='sams'  className='rounded-lg m-3'/> */}
        </div>
      </div>
        
      

    </div>
  )
}

export default Establecimientos
 