import Lista from "../../Helpers/Select";
const Persona=({
    nombre, setNombre,
    apaterno, setApaterno,
    amaterno, setAmaterno,
    rfc, setRFC,
    curp, setCURP,
    nss, setNSS,
    num_identicacion, setNum_identificacion,
    tipo_persona, setTipoPersona
})=>{

    return(
        <>
        <h1 className='title-control'>Datos del Cliente </h1> 
           <div className='div-control p-3 w-full grid grid-cols-6'>                          
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-2'>
                    <label className='label-control '>Nombre</label>             
                    <input type='text' className="form-control " required value={nombre} onChange={({target: {value}})=>setNombre(value)}/>
                </div>
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-2'>
                    <label className='label-control'>Apellido Paterno</label>             
                    <input type='text' className="form-control " required value={apaterno} onChange={({target: {value}})=>setApaterno(value)}/>
                </div>
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-2'>
                    <label className='label-control'>Apellido Materno</label>             
                    <input type='text' className="form-control " required value={amaterno} onChange={({target: {value}})=>setAmaterno(value)}/>
                </div>
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-1'>
                    <Lista label={'Tipo de Persona'} options={tipo_persona} setOnChange={setTipoPersona}/>
                </div>
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-1'>
                    <Lista label={'Régimen Fiscal'}/>
                </div>
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-1'>                    
                    <Lista label={'Genero'}/>
                </div>
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-1'>
                    <label className='label-control'>RFC</label>             
                    <input type='text' className="form-control " required value={rfc} onChange={({target: {value}})=>setRFC(value)}/>
                </div>                
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-1'>
                    <label className='label-control'>CURP</label>             
                    <input type='text' className="form-control " required value={curp} onChange={({target: {value}})=>setCURP(value)}/>
                </div>
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-1'>
                    <label className='label-control'>NSS</label>             
                    <input type='text' className="form-control " required value={nss} onChange={({target: {value}})=>setNSS(value)}/>
                </div>                               
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-2'>
                    <Lista label={'Estado Cívil'}/>
                </div>
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-2'>
                    <Lista label={'Grado de Estudios'}/>
                </div>
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-1'>
                    <Lista label={'Tipo de Identificación'}/>
                </div>
                <div className='w-full block  sm:col-span-6 md:col-span-3 lg:col-span-1'>
                    <label className='label-control'>Número de Identificación</label>             
                    <input type='text' className="form-control " required value={num_identicacion} onChange={({target: {value}})=>setNum_identificacion(value)}/>
                </div>                                                                          
          </div> 
        </>
    )
}

export default Persona;