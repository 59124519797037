import React from 'react'
import MensajeOK from './MensajeOk'
import clienteAxios from '../../config/axiox';
import { useState, useEffect } from 'react';
import Select from 'react-select';

const RegistroUsuario = ({token, usuarios, usuariosLista, consultarUsuarios}) => {

  const [idusuario, setIdUsuario]=useState(0);
  const [idPerfil, setIdPerfil]=useState("");
  const [celular, setCelular]=useState("");
  const [correo, setCorreo]=useState("");
  const [nombre, setNombre]=useState("");
  const [casa, setCasa]=useState("");
  const [clave, setClave]=useState("");
  const [clave2, setClave2]=useState("");
  const [perfiles, setPerfiles]=useState([]);
  const [perfil, setPerfil]=useState("");
  const [message, setMessage]=useState("");
  const [showFormulario, setShowFormulario]=useState(false)
  const [nuevo, setNuevo]=useState(false)
  const [selected_option, setSelected_option]=useState({})
  

  useEffect(()=>{       
    consultarPerfiles()
  },[]) 

  useEffect(()=>{       
    
    
  },[showFormulario]) 
  
  const limpiar=()=>{
      setCelular("")
      setCorreo("")
      setNombre("")
      setCasa("")
      setClave("")
      setClave2("")
      setPerfil("")
      setMessage("")
      setIdPerfil("")
      setNuevo(false)
  }   
  const consultarPerfiles=async()=>{
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }
    if(token){
        const datos = {
          "sp":"usuarios",    
          "op":"perfiles"    
      }
        let Valores=[]           
           await clienteAxios.post(`api/sp`, datos, config)
            .then(res=>{                                                                                                                                                    
                res.data.data[0].forEach(element => {                                                         
                    const {idperfil, perfil}=element                   
                    let dato={value:idperfil, label: perfil}            
                    Valores=[...Valores, dato]                 
                 });                                  
                 setPerfiles(Valores)                     
            }).catch(err=>{
                console.log(err)                
            });                                                                                              
    }
  }  
  const RegistroUsuario =async(event)=>{
    event.preventDefault();     
    if(clave!=clave2){      
      setMessage("La clave no coincide, verifique por favor.!")
    }else {            
      const config = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
          }
      }     
      if(token){    
      const datos = {
          "sp":"usuarios",                
          "op": nuevo ? "registro":"actualizar",                
          "celular":celular,
          "nombre":nombre,
          "correo":correo,
          "casa":casa,
          "clave":clave,
          "perfil":perfil,
          "idusuario": idusuario
      }                  
        await clienteAxios.post(`api/sp/`, datos, config)
          .then(res=>{                                    
           let {error}= res.data.data[0]                                                   
              let data= res.data.data[0];              
              data.forEach(x=>{                  
                let {message, MsgError}=x                                
                  setMessage(message)                  
                  if(MsgError===0){     
                    alert(message)
                    limpiar()
                    consultarUsuarios()
                  }
              })                                                
            const interval = setInterval(() => {            
            }, 3000);
          })
        }
    }
  }

  const mostrarFormulario=()=>{        
    setShowFormulario(true)
  }

  const registrarUsuario=()=>{        
    setShowFormulario(true)
    setNuevo(true)
  }
  const CancelarRegistro=()=>{
    setShowFormulario(false)
    limpiar()
  }

  const editarUsuario =(id)=>{          
    let dato=usuariosLista.filter(x=> x.idusuario===id);
    let {casa, celular, correo, idperfil, idusuario, nombre, perfil}=dato[0];
    setCasa(casa);
    setCelular(celular);    
    setCorreo(correo);
    setIdPerfil(idperfil);
    setIdUsuario(idusuario);
    setNombre(nombre);
    mostrarFormulario();
    setNuevo(false);    
    setSelected_option({value:idperfil, label:perfil})
  }
  
  return (
    <>
        {message &&
          <div className="bg-red-700 border border-red-700 p-1 mt-2 rounded relative text-white" role="alert">
              <span className='bg-red-700 absolute -right-1 -top-1 text-white rounded-full p-1 font-bold text-lg border-2 border-white'
               >x</span>
              {message}                    
          </div> 
        }



      {showFormulario ?
      <>
     
     <div className=' right-0 text-right pe-5'>
        <button className='boton-control-cancel '  onClick={CancelarRegistro}>Cancelar</button>
      </div>
      <h1 className='title-control'>Registro de Usuario </h1> 
      <form onSubmit={RegistroUsuario}>            
          <div className='div-control p-3'>          
            
              <label className='label-control'>Número de Celular</label>             
              <input type='text' className="form-control " required value={celular} onChange={({target: {value}})=>setCelular(value)}/>
              <label className='label-control'>Nombre</label>
              <input type='text' className="form-control " required value={nombre} onChange={({target: {value}})=>setNombre(value)}/>
              <label className='label-control'>Correo</label>
              <input type='email' className="form-control " required value={correo} onChange={({target: {value}})=>setCorreo(value)}/>
              <label className='label-control'>Número de Casa</label>
              <input type='number' className="form-control " required value={casa} onChange={({target: {value}})=>setCasa(value)}/>
              {idPerfil=='99' &&
              <>
                <label className='label-control'>Perfil</label>
                <Select 
                  isSearchable="true"
                  defaultValue={{ label: "Seleccionar Perfil", value: 0 }}
                  className="basic-multi-select" 
                  classNamePrefix="select" 
                  options={perfiles} 
                  value={selected_option}
                  onChange={(item)=>setPerfil(item)} 
                  required               
                />
                </>
              }
              {nuevo &&
              <>
                <label className='label-control'>Clave</label>
                <input type='password' className="form-control " required value={clave} onChange={({target: {value}})=>setClave(value)}/>
                <label className='label-control'>Verificación de Clave</label>
                <input type='password' className="form-control " required value={clave2} onChange={({target: {value}})=>setClave2(value)}/>                                                                  
              </>
              }
              <button className="boton-control " type="submit">
                {nuevo ? 'Registrar':'Actualizar'}
                </button>
             
          </div>        
      </form>     
      </>
      :
      <>
       <div className=' right-0 text-right pe-5'>
        <button className='boton-control-sm' onClick={registrarUsuario} >Registrar Usuario</button>
      </div>
      <h1 className='title-control'>Listado de Usuarios</h1>
      <table className='table-auto ms-10 w-4 mt-3'>  
        <tr>
            <th className='table_colums_title'>No</th>
            <th className='table_colums_title'>Casa</th>
            <th className='table_colums_title'>Nombre</th>
            <th className='table_colums_title'>Celular</th>                
            <th className='table_colums_title'>Perfil</th>
        </tr>
        {usuarios && usuarios.map((dato, index)=>(                                                                              
                  <tr className='table_rows'>
                    <td className='table_colums'>{dato.idusuario}</td>            
                    <td className='table_colums'><span className='font-normal'>{dato.casa}</span></td>   
                    <td className='table_colums whitespace-nowrap'><span className='font-normal'>{dato.nombre}</span></td>            
                    <td className='table_colums'><span className='font-normal'>{dato.celular}</span></td>                            
                    <td className='table_colums'><span className='font-normal'>{dato.perfil}</span></td>       
                    <td className='p-2 cursor-pointer' >
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" onClick={({target: {value}})=>editarUsuario(dato.idusuario)}>
                          <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h357l-80 80H200v560h560v-278l80-80v358q0 33-23.5 56.5T760-120H200Zm280-360ZM360-360v-170l367-367q12-12 27-18t30-6q16 0 30.5 6t26.5 18l56 57q11 12 17 26.5t6 29.5q0 15-5.5 29.5T897-728L530-360H360Zm481-424-56-56 56 56ZM440-440h56l232-232-28-28-29-28-231 231v57Zm260-260-29-28 29 28 28 28-28-28Z"/></svg>
                    </td>     
                  </tr>                                     
          ))}
          </table>  
       </>
      }
    </>
  )
}

export default RegistroUsuario
