import { useState, useEffect } from 'react';
import clienteAxios from '../../config/axiox';
import ListadoRecibos  from './ListadoRecibos'
import ListarGastos from './ListarGastos';
import ListarAdeudos from './ListarAdeudos';
import Establecimientos from './Establecimientos';
import Casas from './Casas'
import EdoCta202307 from '../../EdoCta/202307.pdf';
import EdoCta202308 from '../../EdoCta/202308.pdf';
import EdoCta202309 from '../../EdoCta/202309.pdf';
import EdoCta202310 from '../../EdoCta/202310.pdf';
import EdoCta202311 from '../../EdoCta/202311.pdf';
import EdoCta202312 from '../../EdoCta/202312.pdf';
import RegistroPagos from './RegistroPago';
import RegistroGastos from './RegistroGasto';
import RegistroUsuarios from './RegistroUsuario';
import RegistroClientes from './RegistroClientes';
import ListarDetalle from './ListarDetalle';

const Home = ({
        setCasa, SetFechaInicial, setEmail, setUserName,  SetFechaFinal, userName, userEmail, recibos,    
        fechaFinal, token, setRecibos, fechaInicial, setClave, setToken, setCelular, setLogin, casa, perfil, idperfil, setPerfil,setIDPerfil,
        loading, setLoading
    })=>{
const [showGastos, setShowGastos]=useState(false)
const [showRecibos, setShowrecibos]=useState(false)      
const [showAdeudos, setShowAdeudos]=useState(false)
const [showDeudores, setShowDeudores]=useState(false)
const [showRegistroPago, setshowRegistroPago]=useState(false)
const [showRegistroGasto, setshowRegistroGasto]=useState(false)
const [showRegistroUsuario, setshowRegistroUsuario]=useState(false)
const [showRegistroClientes, setshowRegistroClientes]=useState(false)
const [gastos, setGastos]=useState([])
const [adeudos , setAdeudos]=useState([])
const [usuarios , setUsuarios]=useState([])
const [usuariosLista , setUsuariosLista]=useState([])
const [deudores , setDeudores]=useState([])
const [mi_AdeudoTotal, setMi_AdeudoTotal]=useState(0)
const [adeudosCerrada , setAdeudosCerrada]=useState('')
const [menuMovil , setMenuMovil ]=useState(false)
const [filtros , setFiltros ]=useState(false)
const [detalle , setDetalle]=useState([])
 
useEffect(()=>{    
    consultarRecibos()         
    setMenuMovil(false)
},[]) 

    const mostrarMenu=()=>{
        setMenuMovil(!menuMovil)
    }
    const CerrarSesion= async(event)=>{       
        event.preventDefault();
        cleanLocalStorage();    
        setMenuMovil(false)  
    }

    const consultarUsuarios=async()=>{        
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }
    
        if(token){
            const datos = {
                "sp":"usuarios",    
                "op":"listar"    
            }  
            let valores=[]       
            setLoading(true)     
            await clienteAxios.post(`api/sp`, datos, config)
                .then(res=>{
                    let datos=res.data.data[0]        
                    setUsuariosLista(datos)                              
                    datos.forEach(element => {                   
                       const {idusuario, celular, nombre, correo, casa, clave, activo, idperfil, perfil}=element
                       let dato={idusuario, celular, nombre, correo, casa, clave, activo, idperfil, perfil}            
                        valores=[...valores, dato]                 
                    });                         
                                                           
                }).catch(err=>{
                    setLoading(false) 
                    console.log(err)
                });                                 
                console.log(usuarios)            
                setUsuarios(valores)                
        }
    }

    const cleanLocalStorage=()=>{
        setLogin(false);
        localStorage.clear()
        setEmail("");
        setUserName("");
        setClave("");  
        setToken("");   
        setCelular("");    
        setPerfil("");
        setIDPerfil(0);
    }
    
    const consultarRecibos=async()=>{
        ocultarSecciones()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }
    
        if(token){
            const datos = {
                "op":"mis_recibos",    
                "casa":casa,
                "fechaInicial":fechaInicial,
                "fechaFinal":fechaFinal
            }     
               let valores=[]      
               setLoading(true)     
               await clienteAxios.post(`api/recibos/`, datos, config)
                .then(res=>{                                                                
                    if(res.data.data.length===0){
                        setShowrecibos(false)
                        setShowGastos(false)
                    }
                    res.data.data.forEach(element => {                   
                       const {recibo, numero, fecha, mes, casa, concepto, monto, medio, observaciones, createdAt, updatedAt}=element
                       let dato={recibo, numero, fecha, mes, casa,concepto, monto, medio, observaciones, createdAt, updatedAt}            
                        valores=[...valores, dato]                 
                    });                                       
                    
                    setShowrecibos(true)
                    setLoading(false)     
                }).catch(err=>{
                    console.log(err)
                    setLoading(false)   
                });                                             
                setRecibos(valores)
        }
    }
    const consultarGastos=async()=>{
        ocultarSecciones()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }
    
        if(token){
            const datos = {
                "op":"consulta",                
                "fechaInicial":fechaInicial,
                "fechaFinal":fechaFinal
            }     
            let valores=[]       
            setLoading(true)     
               await clienteAxios.post(`api/egresos/`, datos, config)
                .then(res=>{
                    res.data.data.forEach(element => {                   
                       const {idegreso, fecha, monto, concepto, notas, nombre, recibos, createdAt, updatedAt}=element
                       let dato={idegreso, fecha, monto, concepto, notas, nombre, recibos, createdAt, updatedAt}            
                        valores=[...valores, dato]                 
                    });                         
                   
                    setShowGastos(true)
                    setLoading(false) 
                }).catch(err=>{
                    setLoading(false) 
                    console.log(err)
                });                                             
                setGastos(valores)                
        }
    }
    
    const consultarAdeudos=async()=>{
        ocultarSecciones()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }
   
        if(token){
            const datos = {
                "casa":casa
            }     
            let valores=[]          
            setLoading(true)  
            let valoresGenerales=[] 
            let valoresPorPagar=[] 
            let detalle=[] 
               await clienteAxios.post(`/api/recibos/adeudos`, datos, config)
                .then(res=>{                                                                
                     
                    
                    res.data.data.mis_adeudos.forEach(element => {                                      
                       const {mes, anio, cuota}=element
                       let dato={mes, anio, cuota}            
                        valores=[...valores, dato]                 
                    });  

                    res.data.data.porpagar.forEach(element => {                                      
                        const {id, casa, mes , anio, cuota, pago}=element
                        let dato={id, casa, mes , anio, cuota, pago}            
                        detalle=[...detalle, dato]                 
                     });  

                    
                    res.data.data.topCasas.forEach(element => {                                      
                        const {casa, total}=element
                        let dato={casa, total}            
                        valoresGenerales=[...valoresGenerales, dato]                 
                     });  


                   
                    // res.data.data.porpagar.forEach(element => {                                      
                    //     const {id, periodo}=element
                    //     let dato={id, periodo}            
                    //     valoresPorPagar=[...valoresPorPagar, dato]                 
                    //  });  
                    
                     

                    let mi_adeudo=res.data.data.total_adeudo[0].total                                         
                    setMi_AdeudoTotal(mi_adeudo)                          
                    setDetalle(detalle)
                    setShowAdeudos(true)  
                    setLoading(false) 
                }).catch(err=>{
                    setLoading(false) 
                    console.log(err)
                });                                             
               
                setAdeudos(valores)                              
                setAdeudosCerrada(valoresGenerales)                 
        }
    }

    const ConsultarDetalle=async()=>{
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }
    
        if(token){
            const datos = {
                "casa":casa,
                "id":0,
                "pago":0,
            }     
            let valores=[]                       
               await clienteAxios.post(`api/adeudos`, datos, config)
                .then(res=>{                                                                
                     
                    res.data.data.forEach(element => {                                      
                       const {id, mes, numero_mes, anio, cuota, pago, casa}=element
                       let dato={id, mes, numero_mes, anio, cuota, pago, casa}            
                        valores=[...valores, dato]                 
                    });                                                                                                                             
                    
                      
                }).catch(err=>{
                    console.log(err)
                });        
                ocultarSecciones()                                     
                setShowDeudores(true)                
                setDeudores(valores)
                
                 
        }
    }
    
    const ocultarSecciones=()=>{
        setShowGastos(false)
        setShowrecibos(false)
        setShowAdeudos(false)  
        setshowRegistroPago(false)
        setshowRegistroGasto(false)
        setShowDeudores(false)
        setshowRegistroUsuario(false)
        setMenuMovil(false)  
        setshowRegistroClientes(false)
    }

    const mostrarFormularioPagos=()=>{                
        ocultarSecciones()
        setshowRegistroPago(true)
    }
    const mostrarFormularioGastos=()=>{
        ocultarSecciones()
        setshowRegistroGasto(true)
    }
    const mostrarFormularioUsuarios=()=>{
        consultarUsuarios()
        ocultarSecciones()        
        setshowRegistroUsuario(true)
    }
    const mostrarFormularioClientes=()=>{        
        ocultarSecciones()        
        setshowRegistroClientes(true)
    }
    const mostrarFiltros=()=>{
        setMenuMovil(false)  
        setFiltros(!filtros)
    }

  return (
    <>
        <div className='w-full mx-auto  bg-azul-obscuro md:absolute  text-white right-0  top-0 '>                                                        
                <nav className='flex justify-between lg:justify-start items-center '>
                    <div className='logo'></div>
                        <div className='links hidden lg:block w-full md:4/6'>
                            <ul className='menu flex items-center justify-center gap-5'>
                                <li className='link' onClick={CerrarSesion}>Salir</li>
                                <li className='link bg-lime-500 hover:bg-lime-600 ' onClick={consultarRecibos}>Recibos</li>
                                <li className='link bg-orange-500 hover:bg-orange-600' onClick={consultarGastos}>Gastos</li>
                                <li className='link bg-red-500 hover:bg-red-600' onClick={consultarAdeudos}>Adeudos</li>                     
                                {idperfil=='99' &&
                                <>
                                    <li className='link bg-purple-800 hover:bg-purple-800 ' onClick={mostrarFormularioPagos}>Registrar Pago </li>
                                    <li className='link bg-sky-500 hover:bg-sky-600 '       onClick={mostrarFormularioGastos}>Registrar Gasto </li>
                                    {/* <li className='link bg-amber-500 hover:bg-amber-700 '   onClick={mostrarFormularioUsuarios}>Usuarios </li> */}
                                    {/* <li className='link bg-green-700 hover:bg-green-800 '   onClick={mostrarFormularioClientes}>Clientes </li>                                                                                             */}
                                </>
                                }
                            </ul>
                        </div>                        
                <div className='menu_movil links block lg:hidden w-full md:4/6 '>  
                    <div className='logo'></div>              
                    <button className='p-4 text-white' onClick={mostrarMenu}>
                        <svg className='text-white bg-white' xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                            <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/>
                        </svg>
                    </button>
                    {menuMovil &&
                    <ul className='menu items-center justify-center text-start w-full absolute z-50 left-0 bg-azul-obscuro '>
                        <li className='link' onClick={CerrarSesion}>Salir</li>
                        <li className='link bg-lime-500 hover:bg-lime-600' onClick={consultarRecibos}>Recibos</li>
                        <li className='link bg-orange-500 hover:bg-orange-600' onClick={consultarGastos}>Gastos</li>
                        <li className='link bg-red-500 hover:bg-red-600' onClick={consultarAdeudos}>Adeudos</li>

                        {idperfil=='99' &&
                                <>
                                    <li className='link bg-purple-800 hover:bg-purple-800 ' onClick={mostrarFormularioPagos}>Registrar Pago </li>
                                    <li className='link bg-sky-500 hover:bg-sky-600 '       onClick={mostrarFormularioGastos}>Registrar Gasto </li>
                                    {/* <li className='link bg-amber-500 hover:bg-amber-500 '   onClick={mostrarFormularioUsuarios}>Usuarios </li>
                                    <li className='link bg-amber-500 hover:bg-amber-500 '   onClick={mostrarFormularioUsuarios}>Usuarios </li>  */}
                                    {/* <li className='link bg-green-700 hover:bg-green-800 '   onClick={mostrarFormularioClientes}>Clientes </li> */}
                                </>
                        }
                    </ul>
                    }
                </div>
            </nav>                         
        </div> 
        <div className='container md:flex lg:mt-10'>            
            <button onClick={mostrarFiltros} className='absolute top-1 right-0 me-2 bg-sky-500 hover:bg-sky-600 p-2 rounded-2xl text-white '>Filtros</button>
            {filtros===true &&
            <form onSubmit={CerrarSesion} className='md:w-2/5 lg:2/5 md:h-screen bg-orange-200 shadow-lg p-5  z-1'>                         
                <div className='Filtros'>                  
                    <p className='font-bold block mt-1 w-full'>Nombre <span className='font-normal block'>{userName}</span>  ({perfil})</p>
                    <p className='font-bold block mt-1 mb-1 w-full'>Email <span className='font-normal block'>{userEmail}</span></p>  
                    <p className='font-bold block mt-1 mb-1 w-full'>Casa <span className='font-normal block'>{casa}</span></p>  
                                        
                     <Casas userEmail={userEmail} casa={casa} setCasa={setCasa} idperfil={idperfil} />                                           
                    <label className='label-control'>Fecha Inicio</label>
                    <input type='date' className='form-control ' onChange={({target: {value}})=>SetFechaInicial(value)}/>
                    <label className='label-control'>Fecha Final</label>
                    <input type='date' className='form-control' onChange={({target: {value}})=>SetFechaFinal(value)}/>   
                    <div className='py-5'>
                        <span className='font-bold text-center w-full text-md lg:text-xl cursor-pointer mt-10 ' onClick={ocultarSecciones}>¿Dónde puedo pagar el mantenimiento?</span>  
                    </div>                                      
                    <h1 className='font-bold mt-10'>Estados de Cuenta</h1>                
                    <p><a href = {EdoCta202307} target = "_blank" className='m-10 text-blue-600'> Julio 2023</a></p>                    
                    <p><a href = {EdoCta202308} target = "_blank" className='m-10 text-blue-600'> Agosto 2023</a></p>                    
                    <p><a href = {EdoCta202309} target = "_blank" className='m-10 text-blue-600'> Septiembre 2023</a></p>                    
                    <p><a href = {EdoCta202310} target = "_blank" className='m-10 text-blue-600'> Octubre 2023</a></p>                    
                    <p><a href = {EdoCta202311} target = "_blank" className='m-10 text-blue-600'> Noviembre 2023</a></p>                    
                    <p><a href = {EdoCta202312} target = "_blank" className='m-10 text-blue-600'> Diciembre 2023</a></p>                    
                </div>            
            </form>
            }

            
            <div className=" md:3/5 lg:3/5 w-full md:h-screen overflow-y-scroll">                                                   
                    {!showRecibos 
                    && !showGastos 
                    && !showAdeudos 
                    && !showRegistroPago 
                    && !showRegistroGasto
                    && !showRegistroUsuario
                    && !showDeudores
                    && <Establecimientos/> }
                    {showRecibos && <ListadoRecibos   recibos={recibos} loading={loading} setLogin={setLoading}/>}               
                    {showGastos  && <ListarGastos     gastos={gastos} loading={loading} setLogin={setLoading}/>}
                    {showRegistroPago &&<RegistroPagos userEmail={userEmail} casa={casa} setCasa={setCasa} idperfil={idperfil} token={token} loading={loading} setLogin={setLoading}/>}
                    {showRegistroGasto && <RegistroGastos token={token} loading={loading} setLogin={setLoading}/>}
                    {showRegistroUsuario && <RegistroUsuarios loading={loading} setLogin={setLoading} token={token} usuarios={usuarios} setUsuarios={setUsuarios} usuariosLista={usuariosLista} consultarUsuarios={consultarUsuarios}/>}
                    {showAdeudos  && <ListarAdeudos  adeudos={adeudos} mi_AdeudoTotal={mi_AdeudoTotal} adeudosCerrada={adeudosCerrada} loading={loading} setLogin={setLoading} detalle={detalle}/>}
                    {showDeudores  && <ListarDetalle deudores={deudores} loading={loading} setLogin={setLoading}/>}
                    {showRegistroClientes && <RegistroClientes loading={loading} setLogin={setLoading} token={token} usuarios={usuarios} setUsuarios={setUsuarios} usuariosLista={usuariosLista} consultarUsuarios={consultarUsuarios}/>}
            </div>                            
            </div>
    </>
  )
}

export default Home;