
 const MensajeError = ({msgError}) => {
   return (
     
        <div className={msgError?"bg-red-100 border border-red-400 text-red-700 p-1 mt-2 rounded relative":""} role="alert">
                {msgError}
        </div> 
     
   )
 }
 
 export default MensajeError
 