import { Outlet } from "react-router-dom"
 
 function Layout() {
   return (
  <>
                
        <Outlet/>
        <footer className="w-full flex flex-col items-end">
          <div className='w-full h-full' >
            <div className='bg-azul-obscuro w-full  mx-auto p-10'>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grap-2 justify-center'>                                              
                        <div className='mb-2 text-blanco text-start sm:p-5 md:p-2'>
                          <h2>Administración de Condominios y Fraccionamientos </h2>
                        </div>
                        
                        <div className='mb-5 text-start sm:p-5 md:p-2'>
                          
                          <h1 className='text-blanco'>&copy;<a href="https://we-code.com.mx/" target="_blank" >Desarrollado por <span>We-Code</span> </a> -2024 All Rights Reserved</h1>
                           
      
     
                        </div>

              </div>                    
            </div>
        </div>
        </footer>
      
      </>       
   )
 }
 
 export default Layout
 