import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/normalize.css';
import './css/index.css';
 import Login from './components/login'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './components/login/Layout';
import Registro from './components/login/Registro';
 import Reglamento from './components/login/Reglamento';
 import ListarDetalle from './components/login/ListarDetalle';

const router=createBrowserRouter([
  {
    path:'/',
    element: <Layout/>,
    children:[{
      path:'/',
      element:<Login/>
    }]
  },
  {
    path:'/registro',
    element: <Layout/>,
    children:[{
      path:'/registro',
      element:<Registro/>
    }]
  },
  {
    path:'/adeudos',
    element: <Layout/>,
    children:[{
      path:'/adeudos',
      element:<ListarDetalle/>
    }]
  },
  {
    path:'/reglamento',
    element: <Layout/>,
    children:[{
      path:'/reglamento',
      element:<Reglamento/>
    }]
  }
])
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <RouterProvider router={router}/>
  </React.StrictMode>
);


