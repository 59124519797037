import React from 'react'
import Cargador from './Cargador'

const ListarAdeudos = ({adeudos, mi_AdeudoTotal, adeudosCerrada, loading, detalle}) => {

    const formatoMexico = (number=0) => {
        const exp = /(\d)(?=(\d{3})+(?!\d))/g;
        const rep = '$1,';
        return number.toString().replace(exp,rep);
      }      
  return (
    <>
    {loading && <Cargador/>}
    
    <h1 className='text-start font-bold text-lg ms-10 mt-20 w-full bg-slate-400 p-4' >Adeudo General  </h1>        
   <table className='table-auto ms-10 w-4 mt-3'>
    <tr>
        <th className='font-bold uppercase w-full text-md'>Casa</th>
        <th className='font-bold uppercase w-full text-md'>Monto</th>        
    </tr>
    {adeudosCerrada && adeudosCerrada.map((dato, index)=>(                                                            
          <tr className=''>
                <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className='font-bold'>{dato.casa}</span></td>            
                <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className='font-bold text-red-600'>${formatoMexico(dato.total)}</span></td>                        
          </tr>                                                                                                     
      ))}
      </table>

    <h1 className='text-center font-bold text-2xl ' >Adeudos </h1>
    <h1 className='text-start font-bold text-lg ms-10 w-full bg-slate-400 p-4' >Mi adeudo total es por: <span className='text-red-600'>${formatoMexico(mi_AdeudoTotal)}.00</span> </h1>
    <h1 className='text-start font-bold text-lg ms-10 mt-5' >Detalle: </h1>
   <table className='table-auto ms-10 w-4 mt-3'>
    <tr>
        <th className='font-bold uppercase w-full text-md'>Año</th>
        <th className='font-bold uppercase w-full text-md'>Mes</th>
        <th className='font-bold uppercase w-full text-md'>Adeudo</th>
    </tr>
    {adeudos && adeudos.map((dato, index)=>(                                                            
          <tr className=''>
                <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className='font-normal'>{dato.anio}</span></td>            
                <td className='font-bold uppercase w-full whitespace-nowrap text-md px-4 bg-white rounded-lg p-2 m-1'><span className='font-normal'>{dato.mes}</span></td>            
                <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className=' text-red-600'>${formatoMexico(dato.cuota)}.00</span></td>                            
          </tr>                 
      ))}
      </table>

      <h1 className='text-start font-bold text-lg ms-10 mt-20 w-full bg-slate-400 p-4' >Detalle de Pagos  </h1>        
   <table className='table-auto ms-10 w-4 mt-3'>
    <tr>        
        <th className='font-bold uppercase w-full text-md'>Casa</th>        
        <th className='font-bold uppercase w-full text-md'>Año</th>        
        <th className='font-bold uppercase w-full text-md'>Mes</th>        
        <th className='font-bold uppercase w-full text-md'>Cuota</th>        
        <th className='font-bold uppercase w-full text-md'>Pago</th>        
    </tr>
    {detalle && detalle.map((dato, index)=>(                                                            
          <tr className=''>                
                <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className='font-bold'>{dato.casa}</span></td>            
                <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className='font-bold'>{dato.anio}</span></td>            
                <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className='font-bold'>{dato.mes}</span></td>            
                <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className='font-bold text-black'>${formatoMexico(dato.cuota)}</span></td>                        
                <td className='font-bold uppercase w-full text-md bg-white rounded-lg p-2 m-1'><span className={dato.pago<dato.cuota ? 'text-red-600': 'text-green-600'}>${formatoMexico(dato.pago)}</span></td>                        
          </tr>                                                                                                     
      ))}
      </table>

    
    </>
  )
}

export default ListarAdeudos
